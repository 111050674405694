<script lang="ts" setup>
import type { Participant } from "~/types";

const { participants } = defineProps<{
  participants: Participant[];
  title?: string;
}>();

const participantsList = computed(() =>
  participants.reduce((acc, { username }, index) => `${acc}${index ? ", " : ""}${username}`, "")
);
const show = ref(false);
const userProfileStore = useUserProfileStore();
</script>

<template>
  <BasicTooltip v-if="participants?.length" side="bottom">
    <template #trigger>
      <TooltipTrigger asChild>
        <button
          aria-label="channel.participantsShow"
          :class="
            cn('p-0.5 pr-1 rounded-xl flex gap-1 items-center', 'border transition hover:border-strong')
          "
          @click="show = true"
        >
          <ul class="flex pointer-events-none">
            <li
              v-for="(user, index) in participants.slice(0, 3)"
              :key="user.username"
              :class="cn('relative border-2 rounded-lg select-none', { '-ml-4': index })"
              :style="{ zIndex: participants.length - index }"
            >
              <Avatar as="div" :username="user.username" />
            </li>
          </ul>
          <p v-if="participants?.length" class="text-sm px-1">
            {{ participants?.length }}
          </p>
        </button>
      </TooltipTrigger>
    </template>
    <template #content>
      <p class="font-semibold">{{ $t("channel.participantsShow") }}</p>
      <p>Enthält {{ participantsList }}</p>
    </template>
  </BasicTooltip>
  <BasicDialog v-model:open="show" :title>
    <ul class="flex flex-col">
      <li v-for="{ username } in participants" :key="username">
        <button
          class="w-full flex gap-2 p-1.5 transition cursor-pointer rounded-lg hover:bg-color-1"
          @click="
            () => {
              userProfileStore.setUser(username);
              show = false;
            }
          "
        >
          <Avatar as="span" :username />
          {{ username }}
        </button>
      </li>
    </ul>
  </BasicDialog>
</template>
