<script setup lang="ts">
const { items, command } = defineProps<{
  items: string[];
  command: (id: string) => void;
}>();
const selectedIndex = ref(0);
watch(
  () => items,
  () => {
    selectedIndex.value = 0;
  }
);

const onKeyDown = (event: KeyboardEvent) => {
  if (event.key === "ArrowUp") {
    selectedIndex.value = (selectedIndex.value + items.length - 1) % items.length;
    return true;
  }
  if (event.key === "ArrowDown") {
    selectedIndex.value = (selectedIndex.value + 1) % items.length;
    return true;
  }
  if (event.key === "Enter") {
    event.stopPropagation();
    selectItem(selectedIndex.value);
    return true;
  }
  return false;
};

function selectItem(index: number) {
  const item = items[index];
  if (item) {
    command({ id: item });
  }
}
defineExpose({
  onKeyDown
});
</script>

<template>
  <div class="bg-color-1 rounded-lg border flex flex-col gap-0.5 p-0.5 relative">
    <template v-if="items.length">
      <button
        v-for="(item, index) in items"
        :key="index"
        :class="
          cn(
            'flex items-center gap-1 text-left w-full hover:bg-color-2 px-2 py-1 rounded-md',
            '[&.is-selected]:bg-color-2',
            {
              'is-selected': index === selectedIndex
            }
          )
        "
        @click="selectItem(index)"
      >
        {{ item }}
      </button>
    </template>
    <div v-else class="item">No result</div>
  </div>
</template>
