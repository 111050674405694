<script lang="ts" setup>
const store = useUserProfileStore();
const queryOptions = reactive({ name: store.username });
const { data } = useUserGetByName(queryOptions);
const dayjs = useDayjs();
const { locale } = useI18n();
</script>

<template>
  <header class="flex shrink-0 h-12 pl-5 pr-2 items-center border-b gap-2 bg-color-1">
    <h2 class="h2 grow">{{ $t("user.profile") }}</h2>
    <BasicButton :icon="ICONS.cancel" tooltip="userprofile.close" side="left" @click="store.removeUsername" />
  </header>

  <div class="p-5 grid gap-5">
    <img
      :src="`/api/v1/files/avatar/${data?.username}`"
      alt=""
      class="bg-color-1 w-full aspect-square max-w-xs rounded-xl"
    />
    <h2 class="h1">{{ data?.username }}</h2>

    <div class="grid gap-1">
      <p v-if="data?.pronouns" class="small uppercase">{{ data?.pronouns }}</p>
      <p class="small uppercase">
        {{
          $t("user.joined", {
            date: dayjs(data?.createdAt).format(locale === "de" ? "D. MMMM YYYY" : "MMMM D, YYYY")
          })
        }}
      </p>
    </div>
    <p class="base">{{ data?.bio }}</p>
  </div>
</template>
