<script setup lang="ts">
import type { EmojiExt } from "vue3-emoji-picker";

import type { Editor } from "@tiptap/vue-3";

import EmojiPicker from "../EmojiPicker.vue";

const props = defineProps<{
  editor?: Editor;
  editorIsEmpty: boolean;
}>();

const emits = defineEmits(["send", "cancel"]);

function onEmojiSelect(emoji: EmojiExt) {
  props.editor?.commands.insertContent(emoji.i);
}
</script>

<template>
  <div class="flex gap-1 p-1" aria-label="Formatting options">
    <EmojiPicker tooltip="editor.toolbar2.emojis" @select="onEmojiSelect" />
    <div class="flex gap-1 ml-auto">
      <BasicButton
        size="sm"
        variant="white"
        icon="lucide-send"
        tooltip="editor.toolbar2.send"
        :disabled="editorIsEmpty"
        @click="emits('send')"
      />
      <BasicButton
        size="sm"
        variant="white"
        icon="lucide-x"
        :disabled="editorIsEmpty"
        tooltip="editor.toolbar2.cancel"
        @click="emits('cancel')"
      />
    </div>
  </div>
</template>
