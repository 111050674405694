<script setup lang="ts">
import { Icon } from "@iconify/vue";

import type { Comment } from "~/types";

const { comment } = defineProps<{
  comment: Comment;
}>();
const chatStore = useChatStore();
const threadLength = computed(() => comment.threadAggregate.count);
</script>

<template>
  <div
    v-if="threadLength"
    role="button"
    class="flex items-center gap-2 rounded-md mt-1 p-1 hover:bg-color-1 group/thread"
    @click="chatStore.switchToThread(comment.id)"
  >
    <!-- <Icon class="border bg-color-1 rounded-md size-6 p-0.5 transition" icon="lucide-message-square-text" /> -->
    <Avatar :username="comment.threadAggregate.username" size="default" />
    <div class="flex items-baseline gap-2">
      <p class="font-semibold text-sm">
        {{ threadLength }} {{ $t(`channel.thread.answer.${threadLength === 1 ? "singular" : "plural"}`) }}
      </p>
      <Date
        :date="comment.threadAggregate.lastActivity"
        relative
        class="text-xs text-color-4 block group-hover/thread:hidden"
      />
      <p class="text-xs tracking-wide hidden group-hover/thread:block transition">
        {{ $t("channel.thread.see") }}
      </p>
    </div>
    <Icon
      class="size-4 ml-auto opacity-0 group-hover/thread:opacity-100 transition"
      icon="lucide-chevron-right"
    />
  </div>
</template>
