<script setup lang="ts">
import { Icon } from "@iconify/vue";

const { emoji, commentId, tooltip } = defineProps<{
  name: string;
  emoji: string;
  commentId: string;
  tooltip: string;
}>();

const { username } = useSessionInfo();
const chatStore = useChatStore();
const pending = computed(() => {
  const reactionEvent = chatStore
    .getComment(commentId)
    ?.userReactions?.find(
      (reaction) => reaction.emoji === emoji && reaction.user.username === username.value
    );
  return reactionEvent?.pending;
});
</script>

<template>
  <BasicButton
    size="sm"
    variant="white"
    :class="
      cn('w-8', {
        'pointer-events-none': pending
      })
    "
    :tooltip
    @click.prevent.stop="chatStore.reactToComment(emoji, commentId)"
  >
    <Icon
      v-if="pending"
      class="absolute z-10 size-5 top-[3px] left-[5px] text-color-8 animate-spin"
      icon="lucide:loader"
    />
    <span
      :class="
        cn('text-lg', {
          'opacity-50': pending
        })
      "
    >
      {{ emoji }}
    </span>
  </BasicButton>
</template>
