import { useQuery } from "@tanstack/vue-query";

export default function useUserGetRole(name: string) {
  async function queryFn() {
    const res1 = await GqlGetUserIdByName({ name });
    const res = await GqlGetUserRole({ ids: [res1.user[0].id] });
    return {
      role: res.data[0].role,
      deleted: res1.user[0].deleted
    };
  }

  const context = useQuery({
    queryKey: [QUERY_KEYS.userRole, name],
    queryFn
  });
  useGqlErrorHandling(context.error, context.data);

  return context;
}
