import type { Instance, Props, GetReferenceClientRect } from "tippy.js";
import tippy from "tippy.js";

import { type Editor, VueRenderer } from "@tiptap/vue-3";

import MentionList from "./MentionList.vue";

type SuggestionProps = {
  editor: Editor;
  clientRect: GetReferenceClientRect;
};

export default {
  render: () => {
    let component: VueRenderer;
    let popup: Instance<Props>[];

    return {
      onStart: (props: SuggestionProps) => {
        component = new VueRenderer(MentionList, {
          props,
          editor: props.editor
        });
        if (!props.clientRect) {
          return;
        }
        // @ts-expect-error - tippy expects Element but string selector works too
        popup = tippy("body", {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start"
        }) as Instance<Props>;
      },
      onUpdate(props: SuggestionProps) {
        component.updateProps(props);
        if (!props.clientRect) {
          return;
        }
        popup[0].setProps({
          getReferenceClientRect: props.clientRect
        });
      },
      onKeyDown(props: { event: KeyboardEvent }) {
        if (props.event.key === "Escape") {
          popup[0].hide();
          return true;
        }
        return component.ref?.onKeyDown(props.event);
      },
      onExit() {
        popup[0].destroy();
        component.destroy();
      }
    };
  }
};
